import { startCase } from 'lodash';

const APP_PREFIX = 'smartluxuryride';

function getTitle(vm) {
    const { title } = vm.$options;
    if (title) {
        return typeof title === 'function' ? title.call(vm) : title;
    }

    if (!vm.$route) {
        return null;
    }

    if (vm.$route.meta?.pageTitle) {
        return vm.$route.meta.pageTitle;
    }

    return startCase(vm.$route.name);
}

export default {
    created() {
        const title = getTitle(this);
        if (title) {
            if (['Home', 'Default'].includes(title)) {
                document.title = APP_PREFIX;
            } else {
                document.title = `${APP_PREFIX} - ${title}`;
            }
        }
    },
};
