<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list-item
        :style="{
          background:
            $vuetify.theme.themes[$vuetify.theme.dark ? 'dark' : 'light']
              .primary,
        }"
        class="white--text"
      >
        <v-list-item-content>
          <v-list-item-title class="d-flex justify-left align-center text-h6">
            <v-img
              :lazy-src="compressedLogoUrl"
              max-height="30"
              max-width="30"
              :src="logoUrl"
              @click="$router.push(RoutesConfig.default.path)"
            />
            <span class="ml-3">Smart Luxury Ride</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider />
      <v-list
        nav
        dense
      >
        <v-list-item-group color="primary">
          <v-list-item
            v-for="(tab, index) in tabs"
            :key="index"
            :to="tab.path"
          >
            <v-list-item-title class="text-capitalize">
              {{ tab.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      :color="$vuetify.theme.dark ? '' : 'white'"
      elevation="2"
      flat
    >
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.smAndDown"
        @click.stop="drawer = !drawer"
      />
      <v-img
        :lazy-src="compressedLogoUrl"
        max-height="40"
        max-width="40"
        :src="logoUrl"
        @click="$router.push(RoutesConfig.default.path)"
      />

      <v-tabs
        v-if="!$vuetify.breakpoint.smAndDown"
        align-with-title
      >
        <v-tab
          v-for="(tab, index) in tabs"
          :key="index"
          :to="tab.path"
        >
          {{ tab.title }}
        </v-tab>

        <!-- <v-tab
          v-if="hasPermission(PERMISSIONS.surgesCreate)"
        >
          Admin
        </v-tab> -->
      </v-tabs>
      <!-- <v-menu location="bottom">
        <template #activator="{ on }">
          <v-btn
            color="primary"
            dark
            v-bind="on"
          >
            Dropdown
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in tabs"
            :key="index"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
      <v-spacer />
      <v-btn
        v-if="!user.id"
        rounded
        color="primary"
        @click="isSignInModalOpen = true"
      >
        Sign in
      </v-btn>
      <v-menu
        v-else
        offset-x
        left
        transition="slide-x-transition"
        min-width="200px"
        rounded
      >
        <template #activator="{ on }">
          <v-btn
            icon
            x-large
            v-on="on"
          >
            <v-avatar
              color="primary"
              size="40"
            >
              <span class="white--text">{{ user.initials }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar
                color="primary"
                class="mb-3"
              >
                <span class="white--text">{{ user.initials }}</span>
              </v-avatar>
              <h3 style="max-width: 230px">
                {{ user.fullName }}
              </h3>
              <p class="text-caption mt-1">
                {{ user.email }}
              </p>
              <v-divider
                v-if="hasPermission(PERMISSIONS.usersEdit)"
                class="my-3"
              />
              <v-btn
                v-if="hasPermission(PERMISSIONS.usersEdit)"
                depressed
                text
                @click="$router.push(`/users/${user.id}/profile-info`)"
              >
                <v-icon class="mr-2">
                  mdi-pencil
                </v-icon>
                Edit Account
              </v-btn>
              <div v-if="hasPermission(PERMISSIONS.settingsView)">
                <v-divider
                  v-if="hasPermission(PERMISSIONS.settingsView)"
                  class="my-3"
                />
                <v-btn
                  depressed
                  text
                  @click="
                    $router.push(
                      hasPermission(PERMISSIONS.rolesView)
                        ? RoutesConfig.settings.rolesPath
                        : RoutesConfig.settings.configurationPath
                    )
                  "
                >
                  <v-icon class="mr-2">
                    mdi-cog
                  </v-icon>
                  settings
                </v-btn>
              </div>
              <v-divider class="my-3" />

              <div class="d-flex justify-center">
                <v-switch
                  v-model="$vuetify.theme.dark"
                  inset
                  label="Dark Mode"
                  @click="onThemeChange()"
                />
              </div>
              <v-divider class="mb-3" />
              <v-btn
                depressed
                text
                @click="onSignOut()"
              >
                <v-icon class="mr-2">
                  mdi-logout
                </v-icon>
                Signout
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>

    <login-modal
      :open="isSignInModalOpen"
      @close="isSignInModalOpen = false"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import LoginModal from '@/modals/LoginModal.vue';
import { RoutesConfig } from '@/enums';
import { Messages } from '@/enums';
import { PERMISSIONS } from '@/enums';
import { getItemFromLocalStorage, setItemOnLocalStorage } from '@/common';
export default {
    name: 'CustomAppBar',
    components: {
        LoginModal,
    },
    data() {
        return {
            isSignInModalOpen: false,
            RoutesConfig,
            PERMISSIONS,
            activeTab: 0,
            service: [],
            customerMenuList: [],
            drawer: false,
        };
    },
    computed: {
        ...mapState(['user', 'pages']),
        tabs() {
            let list = [];

            if (this.user?.isAdmin) {
                list = [
                    RoutesConfig.default,
                    RoutesConfig.drivers,
                    RoutesConfig.customers,
                    RoutesConfig.affiliates,
                    RoutesConfig.reservations,
                    RoutesConfig.discountCodes,
                    RoutesConfig.addons,
                    RoutesConfig.surges,
                    RoutesConfig.cars,
                    RoutesConfig.staticPageEditor,
                ];
            } else {
                list = [
                    ...list,
                    RoutesConfig.default,
                    ...(this.user?.id ? [RoutesConfig.reservations] : []),
                    ...this.customerMenuList.map((page) => {
                        return { title: page.title, path: `/${page.slug}` };
                    }),
                    RoutesConfig.staticPageEditor,
                ];
            }
            return list
                .map((li) => ({ ...li, title: li?.meta?.title || li?.title }))
                .filter((li) =>
                    li.meta?.permission ? this.hasPermission(li.meta?.permission) : true
                );
        },
    },
    async created() {
        await this.getMe();
        this.setTheme();
        this.setCustomList();
    },
    methods: {
        ...mapActions(['signOut', 'getMe']),
        setTheme() {
            this.$vuetify.theme.dark = JSON.parse(getItemFromLocalStorage('theme'));
        },
        onThemeChange() {
            setItemOnLocalStorage('theme', this.$vuetify.theme.dark);
            // this.$vuetify.theme.themes.options.themeCache.set('theme', this.$vuetify.theme.dark);
        },
        onSignOut() {
            this.signOut();
            this.$router.push(RoutesConfig.default.path);
            setItemOnLocalStorage('theme', false);
            this.setCustomList();
            this.$vuetify.theme.dark = false;
            this.$toast.success(Messages.success.signoutSuccessFully);
        },
        setCustomList() {
            this.customerMenuList = this.pages.filter(
                (page) =>
                    !['privacy-policy', 'terms-and-conditions'].includes(page.slug)
            );
        },
    },
};
</script>
