import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    theme: {
        themes: {
            // options: {
            //     // themeCache: {
            //     get: (key) => localStorage.getItem(key),
            //     set: (key, value) => localStorage.setItem(key, value),
            //     // },
            // },
            light: {
                color: colors.white,
                primary: '#0277BD',
                secondary: '#BDBDBD',
                accent: '#1976D2',
                error: '#D32F2F',
                info: '#0288D1',
                success: '#388E3C',
                warning: '#F57C00',
            },
            dark: {
                primary: '#0277BD',
                secondary: '#BDBDBD',
                accent: '#1976D2',
                error: '#D32F2F',
                info: '#0288D1',
                success: '#388E3C',
                warning: '#F57C00',
            },
        },
    },
});
